// member
export const members = [
    {
        name: 'علی راستی',
        image: require('../../../global/image/members/ali.webp'),
        description: 'بازار یاب و طراح رابط کاربری خوش ذوق مرندی !',
        instagram: 'https://www.instagram.com/alirasti.official?igsh=MWRxdnR5eGVvdGlkYw=='
    },
    {
        name: 'مهدی محمد زاده',
        image: require('../../../global/image/members/mahdi.webp'),
        description: 'بک اند دولوپر حرفه ای و با حوصله',
        instagram: 'https://www.instagram.com/mhdiamz?igsh=a2NxNjFxenU4OXNv',
        github: 'https://github.com/ismahdi',
        linkedin: 'https://www.linkedin.com/in/mahdi-aghamohammadzadeh/',
    },
    {
        name: 'پویا رحمان زاده',
        image: require('../../../global/image/members/pouya.png'),
        description: 'مغز متفکر و بهترین توسعه دهنده اپلیکیشن',
        instagram: 'https://www.instagram.com/p1ouy3a?igsh=MW84cHQ2bXQyaGtzdA==',
        github: 'https://github.com/PouyAium',
        linkedin: 'https://www.linkedin.com/in/pouya-rahmanzadeh-7b5624254/',
    },
    {
        name: 'مهدی ذوالفقار پور',
        image: require('../../../global/image/members/sins.jpg'),
        description: 'فرانت دولوپر خوش سلیقه و حساس',
        instagram: '',
    },
    {
        name: 'امیر اولیایی',
        image: require('../../../global/image/members/amir.webp'),
        description: 'فرانت دولوپر خوش ذوق و دیتیل پرداز',
        instagram: 'https://www.instagram.com/amir.olyai?igsh=MXh3cnN3dXlqNXlkcw==',
        github: 'https://github.com/Amirmohammad-Olyaii',
    },
    {
        name: 'سارا عبداللهی',
        image: require('../../../global/image/members/sara.jpg'),
        description: 'فرانت دولوپر با حوصله و خوش سلیقه',
        instagram: 'https://www.instagram.com/lsara____pvl?igsh=ZW1jemcyYjl1cXdv',
        github: 'https://github.com/Degianus',
    },
]
