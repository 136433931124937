// images
import sliderTwo from "../../../global/image/client/cafe.webp";
import sliderOne from "../../../global/image/client/pizza.jpg";

// options
let sliderOptions = [
    {
        id: 0,
        title: 'کافه روسلایس',
        subtitle: 'کافه خاص برای افراد خاص',
        transfer: 'منو کافه',
        image: sliderTwo,
        link: '/branch/cafe',
    },
    {
        id: 1,
        title: 'رستوران روسلایس',
        subtitle: 'بهترین متریال ها با ما',
        transfer: 'منو رستوران',
        image: sliderOne,
        link: '/branch/restaurant',
    }
]

export default sliderOptions;
