// components
import MenuItems from "../../component/pages/menu/page/menu";

const Menu = () => {
    return (
        <>
            <MenuItems/>
        </>
    )
}

export default Menu;